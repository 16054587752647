.App {
  font-family: 'Montserrat', sans-serif;
  margin: 20px;
  position: relative; /* Ensure that child elements are positioned relative to this container */
}
button{  font-family: 'Montserrat', sans-serif;
}
.add-vendor-btn {
  font-family: 'Montserrat', sans-serif;

  position: absolute;
  top: 0px;
  right: 20px;
  padding: 8px 16px;
  background-color: #1261A0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000; /* Ensure it's on top of other content */
}

.add-vendor-btn:hover {
  background-color: #45a049;
}

h1 {
  font-size: 24px;
}

form div {
  margin-bottom: 10px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

button {
  padding: 8px 16px;
  background-color: #1261A0;
  margin-bottom: 5px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

li button {
  margin-left: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.vendor-form-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.vendor-form-container h2 {
  margin-bottom: 20px;
}

.vendor-form-container form {
  display: grid;
  grid-gap: 15px;
}

.toggle-form-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-form-button:hover {
  background-color: #45a049;
}

.button-group {
  margin-top: 10px;
  text-align: right;
}

.button-group button {
  margin-left: 10px;
}

.modal {
  font-size: large;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.category-filters {
  margin: 20px 0;
}

.category-filters h2 {
  margin-bottom: 10px;
  font-size: 20px;
}

.category-filters .filter-button {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}

.category-filters .filter-button:hover {
  background-color: #1261A0;
  color: white;
}

.category-filters .filter-button.active {
  background-color: #1261A0;
  color: white;
  border-color: #1261A0;
}

@media (max-width: 600px) {
  .category-filters {
    text-align: center;
  }

  .category-filters .filter-button {
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 10px auto;
  }
}
.filters-btn{
  margin-top: 10px;
  margin-bottom: -20px;}
.App-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.App-header .logo {
  width: 140px; /* Adjust as needed */
  height: auto;
  margin-right: 20px;
}

.App-header h1 {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;

}
.login-form {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-form h2 {
  margin-bottom: 20px;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  padding: 10px 20px;
  background-color: #1261A0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0f4c81;
}
@media (max-width: 600px) {
  /* Header */
  .App-header {
    flex-direction: column;
    align-items: center;
  }

  .App-header .logo {
    width: 120px; /* Adjust for smaller screens */
  }

  .App-header h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  /* Buttons */
  .add-vendor-btn,
  .toggle-form-button,
  .button-group button {
    font-size: 14px; /* Adjust font size */
    padding: 6px 12px; /* Adjust padding */
  }

  /* Form Containers */
  .vendor-form-container,
  .login-form {
    max-width: 100%;
    margin: 10px auto;
    padding: 15px;
  }

  .vendor-form-container h2,
  .login-form h2 {
    font-size: 16px; /* Adjust font size */
  }

  .vendor-form-container form,
  .login-form form {
    display: grid;
    grid-gap: 8px; /* Reduce gap */
  }

  .button-group {
    text-align: center;
  }

  .button-group button {
    display: block;
    margin: 8px auto;
  }

  /* Tables */
  table {
    font-size: 12px; /* Reduce font size */
  }

  th, td {
    padding: 12px; /* Adjust padding */
  }

  /* Modal */
  .modal-content {
    width: 70%;
    max-width: 400px;
    padding: 15px; /* Adjust padding */
  }

  /* Filters */
  .category-filters {
    text-align: center;
  }

  .category-filters .filter-button {
    width: 100%;
    max-width: 200px;
    margin: 10px auto;
    font-size: 14px; /* Adjust font size */
    padding: 8px 10px; /* Adjust padding */
  }

  .filters-btn {
    margin-top: 10px;
    margin-bottom: -10px; /* Adjust spacing */
  }
  
}
